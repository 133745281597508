<template>
  <div>
    <vs-row
      vs-w="16"
      vs-type="flex"
      vs-align="flex-start"
      vs-justify="left"
      v-for="({ name, quantity, isFree }, index) in items"
      v-bind:key="index"
    >
      <vs-col vs-w="9" v-if="!isFree">
        <p class="font-12">{{ quantity }} x {{ name }}</p>
      </vs-col>
    </vs-row>
    <p class="font-12 pt-4 pb-1 underline" v-if="freeItems.length">Free Items: </p>
    <vs-row
      vs-w="16"
      vs-type="flex"
      vs-align="flex-start"
      vs-justify="left"
      v-for="({ name, quantity }, index) in freeItems"
      v-bind:key="index"
    >
      <vs-col vs-w="9">
        <p class="font-12">{{ quantity }} x {{ name }}</p>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  name: "BundledItemsDetails",
  props: ["items"],
  computed: {
    freeItems() {
      if (!this.items || !this.items.length) return [];
      return this.items.filter((item) => item.isFree);
    },
  }
};
</script>
<style>
p.font-12 {
  font-size: 12px;
}
</style>
